import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const BeautyPage = () => (
  <Layout descriptionText={"Beauty"}>
    <Seo title="beauty" />

    <Gallery categoryName="beauty" />
  </Layout>
);

export default BeautyPage;
